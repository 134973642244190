<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form :inline="true" size="mini" :model="search">
          <el-form-item label="服务日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w300"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shopId"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入店铺名称"
              class="w120 mr20"
            >
              <el-option
                v-for="item in options.shopId"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>


          <el-form-item label="姓名" >
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

		  <el-form-item label="护理师">
		    <el-input
		      v-model="search.nurse_name"
		      maxlength="30"
		      class="w100"
		      placeholder="请输入"
		      clearable
		    />
		  </el-form-item>

      <el-form-item label="作废订单">
            <el-select v-model="search.is_delete" placeholder="是否显示作废订单" class="w120" clearable>
              <el-option
                v-for="item in options.is_delete"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
      </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>

    <!--列表-->
    <el-row class="mt30">
      <div class="btnBox">
        <el-button
          type="primary"
          class="mb10"
          size="mini"
          @click="exportData('export')"
        >导出数据</el-button>
      </div>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="开卡日期" prop="kk_time" width='140'/>
          <ElTableColumn label="核销日期" prop="create_time" width='140'/>
          <ElTableColumn label="客户姓名" prop="customer_name" width='120' />
          <ElTableColumn label="性别" prop="sex" />
          <ElTableColumn label="年龄" prop="age" />
          <ElTableColumn label="门店" prop="shop_name" />
          <ElTableColumn label="市场" prop="market_name" />
          <ElTableColumn label="负责人" prop="nurse_name" />
		      <!-- <ElTableColumn label="来源" prop="source_name" /> -->

          <ElTableColumn label="卡项分类" prop="category_name" width="100" />
          <ElTableColumn label="卡项名称" prop="name" width="140" />
          <ElTableColumn label="卡项名称-别名" prop="goods_alias_name" width="150" />
          <ElTableColumn label="护理师1" prop="nurse_id_one_name" />
          <ElTableColumn label="护理师2" prop="nurse_id_two_name" />
          <ElTableColumn label="服务类型" prop="experience" />
          <ElTableColumn label="服务次数" prop="fw_num" />
          <ElTableColumn label="核销店长" prop="station_name" />
          <ElTableColumn label="核销平台" prop="pt" />
          <ElTableColumn label="订单状态" prop="status_msg" />
          <ElTableColumn label="备注" prop="mark" />
          <ElTableColumn label="消耗本金" prop="consume_money" />
          <ElTableColumn label="消耗赠金" prop="consume_gratuity" />
          <ElTableColumn label="消耗店转" prop="consume_store_transfer" />
          <ElTableColumn label="作废人" prop="cancel_realname" />
          <ElTableColumn label="操 作"  width="80">
            <template slot-scope="{ row }">
              <span class="theme" @click="tapDetail(row.id)">作 废</span>
            </template>
          </ElTableColumn>
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getShopList } from "@/api/shop";
import pagination from "@/components/Pagination";
import { serveContent,delservicelog } from "@/api/client";

class Search {
  start_time = ""; // 开始日期
  endTime = ""; // 结束日期
  customer_name = ""; // 客户名称
  telephone = ""; // 客户电话
  nurse_name = "";//护理师
  is_delete = 0;
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}

class Options {
  constructor() {
    this.shopId = []; // 店铺
  }
  is_delete = [
    { id: 0, name: "不显示" },
    { id: 1, name: "显示" }
  ];
}

export default {
  name: "serveContent",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(),
      //verifyDetail: new VerifyDetail(),
      options: new Options(),
      //rules: new Rules(),
      page: new Page(), // 分页
      list: [], // 数据展示
      //verificationPop: false, //核销弹出
      timeValue: [],
      value1: [],
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      }
    };
  },

  mounted() {
    this.getList();
  },

  computed: {
    ...mapGetters(["userInfo"])
  },

  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      serveContent({
        ...this.search,
        customer_id: this.clienId,
        ...this.page
      }).then(res => {
        this.list = res.service_log;
        this.page.total = res.dataCount;
      });
     // this.getStaff();
    },

    // 导出
    exportData(option){
      serveContent({
        ...this.search,
        customer_id: this.clienId,
        ...this.page,
        export : 1
      }).then(res => {
        window.open(res.url);
      });
      this.getStaff();
    },

    // 客户所在店铺员工列表
    getStaff() {
      staffList({ page: 1, limit: 1000000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.sale_id = res.list;
        }
      );
    },
   /* // 重复护理师提示
    changeStaff() {
      if (this.verifyDetail.nurse_id_one == this.verifyDetail.nurse_id_two) {
        this.$message({
          type: "warning",
          message: "请选择不同护理师"
        });
        this.verifyDetail.nurse_id_two = "";
      }
    },*/
    // 服务日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.endTime = val[1];
      } else {
        this.search.start_time = "";
        this.search.endTime = "";
        this.getList("search");
      }
    },
    // 点击详情
    tapDetail(id) {
      this.$confirm(`该条核销记录将失效是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          this.nullify(id);
      })
      .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
      });
    },

    nullify(id) {
      delservicelog({ id: id }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getList();
      });
    },
    // 点击核销
    /*verification(id) {
      this.verificationPop = true;
      this.verifyDetail = new VerifyDetail();
      this.verifyDetail.service_id = id;
    },

    // 提交核销
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("use", "核销此客户服务");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },*/
    // 提示

    // 店铺查找
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shopId = res.list;
      });
    },

  }
};
</script>

<style lang="scss" scoped>
</style>
